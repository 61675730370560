import { helpers } from "@vuelidate/validators";
import { wealthaboutTypes } from "../types";
import {
  requiredWithErrorText,
  validateNIF,
  validateCIF,
} from "@/assets/logic/validators";
import AppTextArea from "@/components/_globals/_base/AppTextArea";
import AppInputFiles from "@/components/_globals/_base/AppInputFiles";
import AppInput from "@/components/_globals/_base/AppInput";
import InputWithImage from "@/components/_globals/InputWithImage";
import AppCheckbox from "@/components/_globals/_base/AppCheckbox";
import AppInputDate from "@/components/_globals/_base/AppInputDate";
import { custom } from "@/assets/logic/customize";
import AppDropdown from "@/components/_globals/_base/AppDropdown";

export const feedbackForm = (initData = null) => {
  // Guardamos el contexto para poder acceder a él desde el action
  let contextForm = null;
  // Añadimos al documento un input file para poder adjuntar archivos
  // formatos permitidos: .png, .jpeg, tamaño máximo por imagen: 1MB)
  const input = document.createElement("input");
  input.type = "file";
  input.multiple = true;
  input.accept = ".png, .jpeg";
  input.style.display = "none";
  input.addEventListener("change", event => {
    if (contextForm.formState.files) {
      contextForm.formState.files = [
        ...contextForm.formState.files,
        ...event.target.files,
      ];
    } else {
      contextForm.formState.files = [...event.target.files];
    }
  });
  return {
    onMounted: context => {
      // Guardamos el contexto para poder acceder a él desde el action
      contextForm = context;
    },
    onUnmounted: context => {
      input.remove();
    },
    title: "¿Qué te parece Wealthabout?",
    actions: [
      {
        icon: "attach_file",
        text: "Adjuntar archivo",
        action: () => {
          input.click();
        },
      },
    ],
    description:
      "Queremos hacer de Wealthabout la mejor herramienta de planificación financiera que existe, y para ello necesitamos tu opinión sobre lo que echas de menos, o de más, o simplemente lo mucho que te gusta.\n\nCualquier comentario es bienvenido y nos ayuda a hacer una herramienta mejor y más robusta.",
    initData,
    fields: {
      comment: {
        component: AppTextArea,
        propsComponent: {
          label: "Escribe un comentario",
          maxLength: 2000,
          rows: 5,
        },
        validations: { requiredWithErrorText },
        styles: {},
      },
      files: {
        component: AppInputFiles,
        validations: {
          maxTreeFiles: helpers.withMessage(
            "No puedes adjuntar más de 3 archivos",
            value => value?.length <= 3
          ),
          maxFileSize: helpers.withMessage(
            v$ => {
              const fileNotValid = v$.$model?.find(
                file => file.size > 1 * 1024 * 1024
              );
              if (fileNotValid) {
                const size = fileNotValid?.size / 1024 / 1024;
                return `El tamaño máximo por archivo es de 1MB: ${fileNotValid.name} - ${size.toFixed(2)}MB`;
              }
              return "";
            },
            value => !value?.find(file => file.size > 1 * 1024 * 1024)
          ),
        },
        isHidden: context =>
          !context.formState.files || context.formState.files.length === 0,
      },
      saveData: {
        component: AppCheckbox,
        propsComponent: {
          label:
            "El equipo de Wealthabout no tiene acceso a tus datos. Si la sugerencia o problema que nos comentas requiere el acceso a este wealthabout, nos autorizas a realizar una copia anonimizada que será destruida en un plazo máximo de 7 días desde la generación de la incidencia.",
          initialIsChecked: true,
          // TODO: utilizar variables globales para los estilos
          labelStyle: {
            "font-family": "Lato",
            "font-size": "1.4rem",
            "font-weight": "400",
            "line-height": "1.68rem",
            "text-align": "left",
            color: "#808080",
          },
        },
        default: true,
      },
    },
    createApi: async context => {
      try {
        const wealthaboutId = useRoute().params.wealthaboutid;
        const clientId = useRoute().params.clientid;
        const path = useRoute().path;
        const data = {
          comment: context.formState.comment,
          wealthaboutId,
          clientId,
          path,
          saveData: context.formState.saveData,
        };
        await context.$api.postFeedback(data, context.formState.files || []);
        context.$emit("succeeded");
      } catch (error) {
        return error.response.data;
      }
      return null;
    },
  };
};

export const distributionOwnership = (
  initData,
  { description, members, initDataCreate, useApi, resourceId }
) => {
  const fields = members.reduce((acc, member) => {
    acc[member.id] = {
      component: InputWithImage,
      propsComponent: {
        label: member.alias,
        type: "percentage",
        idImage: member.image,
        defaultLetter: member.alias[0],
        color: context =>
          custom.member.customization[context.$helpers.getRelationship(member)]
            .color,
      },
      default: initDataCreate
        ? initDataCreate[member.id]
          ? initDataCreate[member.id] * 100
          : 0
        : undefined,
      validations: { requiredWithErrorText },
    };
    return acc;
  }, {});
  // Completamos los members iniciales con 0 si no existen
  if (!initDataCreate) {
    initData = members.reduce((acum, current) => {
      acum[current.id] = initData[current.id] * 100 || 0;
      return acum;
    }, {});
  }
  // Metodo para enviar al succeded el belongTo validado
  const checkAndSendDistributionOwnership = async context => {
    const total = members.reduce(
      (acc, member) => acc + context.formState[member.id],
      0
    );
    let response = null;
    if (total > 100) {
      return "La suma de los porcentajes no puede ser superior a 100%";
    }
    const belongTo = members.reduce((acc, member) => {
      const percentage = context.formState[member.id] / 100;
      if (percentage) {
        acc[member.id] = percentage;
      }
      return acc;
    }, {});
    if (useApi) {
      response = await useApi(belongTo, resourceId);
    }
    context.$emit("succeeded", belongTo, response);
    return response;
  };
  return {
    title: "Distribución de titularidad",
    description:
      description || "Introduce el porcentaje de titularidad de cada miembro",
    initData,
    showDeleteButton: false,
    fields,
    createApi: checkAndSendDistributionOwnership,
    updateApi: checkAndSendDistributionOwnership,
    useApi,
  };
};

const template = context => {
  const templateActivo = [
    {
      title: "Información del wealthabout",
      fields: ["wealthaboutType"],
      templateColumns: "repeat(2, 1fr)",
      templateAreas: "'wealthaboutType'",
    },
  ];
  if (context.formState.wealthaboutType === wealthaboutTypes.main) {
    templateActivo.push({
      title: "Información del cliente",
      fields: ["name", "surname", "nif", "birthDate"],
      templateColumns: "repeat(2, 1fr)",
      templateRows: "repeat(2, 1fr)",
      templateAreas: "'name surname' 'nif birthDate'",
    });
  } else {
    templateActivo.push({
      title: "Información de la sociedad",
      fields: ["name", "cif"],
      templateColumns: "repeat(2, 1fr)",
      templateAreas: "'name cif'",
    });
  }

  return templateActivo;
};
export const createClientForm = (initData = null) => {
  return {
    title: "Información básica del cliente",
    description:
      "Es necesario rellenar esta información básica del cliente para poder crear su Wealthabout.",
    initData,
    template,
    fields: {
      wealthaboutType: {
        component: AppDropdown,
        propsComponent: {
          label: "Tipo de cliente",
          options: [
            { id: wealthaboutTypes.main, value: "Físico" },
            { id: wealthaboutTypes.holdingMain, value: "Jurídico" },
          ],
          cleanable: false,
        },
        default: wealthaboutTypes.main,
        validations: { requiredWithErrorText },
      },
      name: {
        component: AppInput,
        propsComponent: { type: "text", label: "Nombre" },
        validations: { requiredWithErrorText },
        default: "",
      },
      surname: {
        component: AppInput,
        propsComponent: { type: "text", label: "Apellidos" },
        validations: { requiredWithErrorText },
        isHidden: context =>
          context.formState.wealthaboutType === wealthaboutTypes.holdingMain,
      },
      nif: {
        component: AppInput,
        propsComponent: { type: "text", label: "DNI" },
        validations: { requiredWithErrorText, validateNIF },
        isHidden: context =>
          context.formState.wealthaboutType === wealthaboutTypes.holdingMain,
      },
      cif: {
        component: AppInput,
        propsComponent: { type: "text", label: "NIF" },
        validations: { requiredWithErrorText, validateCIF },
        isHidden: context =>
          context.formState.wealthaboutType === wealthaboutTypes.main,
      },
      birthDate: {
        component: AppInputDate,
        propsComponent: {
          label: "Fecha de nacimiento",
          locale: "es",
          type: "date",
        },
        validations: { requiredWithErrorText },
        isHidden: context =>
          context.formState.wealthaboutType === wealthaboutTypes.holdingMain,
      },
    },
    createApi: async context => {
      try {
        const formData = new FormData();
        formData.set("is_anonymous", false);
        formData.set("should_run_in_background", false);
        formData.set(
          "initial_data",
          JSON.stringify({
            wealthaboutType: context.formState.wealthaboutType,
            name: context.formState.name,
            surname: context.formState.surname,
            nif:
              context.formState.wealthaboutType === wealthaboutTypes.main
                ? context.formState.nif
                : context.formState.cif,
            birthdate: context.formState.birthDate,
          })
        );

        const response = await context.$api.createClient(formData);
        context.$emit("succeeded", response);
      } catch (error) {
        return error.response.data;
      }
      return null;
    },
  };
};
